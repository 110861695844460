<template>
    <site-navbar/>
    <div class="container mx-auto p-4">
      <form @submit.prevent="editProfile()" class="form-group">
        <!-- Education Background -->
        <div class="mb-4">
          <label for="education_background" class="block text-gray-700 text-sm font-bold mb-2">Education Background</label>
          <VueMultiselect
              placeholder="Select degree"
              v-model="user.education_background.degrees.degreeName"
              :options="degrees"
              :multiple="true"
              :close-on-select="true"
              :hide-selected="true"
              label="degreeName"
              track-by="degreeId"
            >
            </VueMultiselect>
            <label for="education_background" class="block text-gray-700 text-sm font-bold mb-2">School Name</label>
            <input type="text" v-model="user.education_background.schoolName" id="education_background" class="w-full px-3 py-2 border rounded" />

            <label for="education_background" class="block text-gray-700 text-sm font-bold mb-2">Graduation Year</label>
            <input type="text" v-model="user.education_background.graduationYear" id="education_background" class="w-full px-3 py-2 border rounded" />
        </div>
  
        <!-- Biograph -->
        <div class="mb-4">
          <label for="biograph" class="block text-gray-700 text-sm font-bold mb-2">Biograph</label>
          <textarea v-model="user.biograph" id="biograph" class=" px-3 py-2 border rounded"></textarea>
        </div>
  
        <!-- Current Location -->
        <div class="mb-4">
            <label for="location" class="block text-gray-700 text-sm font-bold mb-2">Location</label>

            <div class="">
            <!-- Province, Disctict, sector, cell -->
            <select
              name="province"
              id=""
              v-model="user.current_locations.province"
              @change="getDistricts(user.current_locations.province)"
            >
              <option value="">{{ $txt("Province") }}</option>
              <option
                v-for="province in provinces"
                :key="province.provinceId"
                :value="province.provinceName"
              >
                {{ province.provinceName }}
              </option>
            </select>
            <select
              name="district"
              id=""
              v-model="user.current_locations.district"
              @change="getSectors(user.current_locations.district)"
            >
              <option value="">{{ $txt("District") }}</option>
              <option
                v-for="district in districts"
                :key="district.districtId"
                :value="district.districtName"
              >
                {{ district.districtName }}
              </option>
            </select>
            <select
              name="sector"
              id=""
              v-model="user.current_locations.sector"
              @change="getCells(user.current_locations.sector)"
            >
              <option value="">{{ $txt("Sector") }}</option>
              <option
                v-for="sector in sectors"
                :key="sector.sectorId"
                :value="sector.sectorName"
              >
                {{ sector.sectorName }}
              </option>
            </select>
            <select name="cell" id="" v-model="user.current_locations.cell">
              <option value="">{{ $txt("Cell") }}</option>
              <option
                v-for="cell in cells"
                :key="cell.cellId"
                :value="cell.cellName"
              >
                {{ cell.cellName }}
              </option>
            </select>
          </div>
        </div>
  
        <!-- Submit Button -->
        <div class="flex justify-center">

          <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Submit
          </button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {

        user:{
        education_background: {
            degrees:{
                degreeName: '',
            },
            schoolName: '',
            graduationYear:'',
        },
        biograph: '',
        current_locations: {
            province: '',
            district: '',
            sector: '',
            cell:'',            
        }
        },

        degrees:[],
        provinces:[],
        districts:[],
        sectors:[],
        cells:[],
      }

    },
    methods: {

    editProfile() {
      this.user.token = this.$getToken();

      this.$startLoader();
      this.$store
        .dispatch("EDIT_USER_PROFILE", {
          token: this.$getToken(),
          data: this.user,
        })
        .then((res) => {
          this.$stopLoader();
          if (res.data.status) {
            this.$notify({
              title: "Success",
              type: "success",
              text: "Profile updated successfully",
            });
            this.$stopLoader();
          }
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            type: "error",
            text: err.response.data.message,
          });
          this.$stopLoader();
        });
    },
      getDegrees() {
      this.$startLoader();
      this.$store
        .dispatch("GET_DEGREES", { token: this.$getToken() })
        .then((res) => {
          this.degrees = res.data.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$userLogout();
          }
        });
    },
    getProvinces() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROVINCES", { token: this.$getToken() })
        .then((res) => {
          this.provinces = res.data.data.data;
          this.districts = [];
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getDistricts(provinceId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_DISTRICTS", {
          token: this.$getToken(),
          provinceId: provinceId,
        })
        .then((res) => {
          this.districts = res.data.data.data;
          this.sectors = [];
          this.cells = [];
          this.$stopLoader();
        });
    },
    getSectors(districtId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_SECTORS", {
          token: this.$getToken(),
          districtId: districtId,
        })
        .then((res) => {
          this.sectors = res.data.data.data;
          this.cells = [];
          this.$stopLoader();
        });
    },
    getCells(sectorId) {
      this.$startLoader();
      this.$store
        .dispatch("GET_CELLS", { token: this.$getToken(), sectorId: sectorId })
        .then((res) => {
          this.cells = res.data.data.data;
          this.$stopLoader();
        });
    },
    },
    mounted() {
    this.$store
      .dispatch("GET_LEVELS", { token: this.$getToken() })
      .then((res) => {
        this.levels = res.data.data.data;
      });
    this.getProvinces();
    this.getDegrees();
  },
  };
  </script>
  
  <style scoped lang="scss">
        @import "@/assets/scss/main.scss";

        .form-group{
            background: white;
            border: 1px solid grey;
            border-radius: 5px;
            height: max-content;
            padding: 50px;
        }

        .form-group input,textarea{
            width: 80%;
        }

        .form-group button{
            background-color: $blue;
        }
  </style>
  