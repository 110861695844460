<template>
  <div class="SiteNavbar">
    <div class="top-bar">
      <div class="left">
        <p @click="redirectHome()" style="cursor: pointer;">Infomaster</p>
        <p>
          <a href="mailto:support@infomaster.rw">support@infomaster.rw</a>
        </p>
        <p>
          <a href="tel:+250782539657">+250 782 539 657</a>
        </p>
      </div>
      <div class="right">
        <div class="a" v-click-out-side="customMethod" @click="showDrop = true">
          <change-lang :showDrop="showDrop" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
export default {
  name: "SiteNavbar",
  directives: {
    clickOutSide,
  },
  data() {
    return {
      translatedText: "",
      showDrop: false,
      showAccountDrop: false,
    };
  },
  methods: {
    customMethod() {
      this.showDrop = false;
    },
    customMethod2() {
      this.showAccountDrop = false;
    },
    redirectHome() {
      window.open(process.env.VUE_APP_HOME_URL, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.SiteNavbar {
  .top-bar {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: $blue;

    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 12px;
        font-weight: 600;
        color: #fff;
        margin-right: 20px;
      }
    }

    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .a {
        position: relative;
        cursor: pointer;

        .drop {
          position: absolute;
          top: 30px;
          right: 0;
          width: 100px;
          height: 100px;
          background-color: #fff;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.507);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          z-index: 100;

          .item {
            width: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
              background-color: #f5f5f5;
            }
          }
        }
      }
    }
  }

  .navbar {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.507);

    .navbar-item {
      width: 100vw;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a,
      .a {
        color: #000;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: baseline;
        align-items: center;

        width: calc(100% / 12);

        img {
          width: 30px;
          height: 30px;
        }

        span {
          /* Added style for text */
          display: inline-block;
          margin-top: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
    .top-bar {
      flex-direction: column;
      align-items: flex-start;
      .left,
      .right {
        margin-bottom: 10px;
        p {
          margin-right: 0;
        }
      }
      .right {
        .a {
          margin-right: 0;
        }
      }
    }
    .navbar {
      height: auto;
      .navbar-item {
        flex-wrap: wrap;
        .a {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    .navbar-item .a  span{
      display: none !important;
    }
    .navbar-item img{
      height: 24px !important;
      width: 24px !important;
    }
  }
  @media screen and (max-width: 576px) {
    .top-bar {
      .left,
      .right {
        p {
          font-size: 10px;
        }
      }
    }
  }


</style>
