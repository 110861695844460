<template>
  <div class="SiteFooter">
    <div class="links">
      <router-link
        :to="{ name: 'Education' }"
        v-for="ismodule in ismodules"
        :key="ismodule.id"
        >{{ $txt(ismodule.name) }}</router-link
      >
    </div>
    <div class="socials">
      <a
        href="https://www.facebook.com/InfoMaster-100106255426622"
        target="_blank"
      >
        <i class="fa-brands fa-square-facebook"></i>
      </a>
      <a href="https://twitter.com/InfoMasterRw" target="_blank">
        <i class="fa-brands fa-square-twitter"></i>
      </a>
      <a href="https://www.instagram.com/infomaster.rw/" target="_blank">
        <i class="fa-brands fa-square-instagram"></i>
      </a>
      <a
        href="https://www.linkedin.com/company/infomaster-rwanda"
        target="_blank"
      >
        <i class="fa-brands fa-linkedin"></i>
      </a>
    </div>
    <div class="copyright">
      <p>&copy; {{ currentYear() }} InfoMaster. All Rights Reserved.</p>
    </div>
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
export default {
  name: "SiteFooter",
  directives: {
    clickOutSide,
  },
  data() {
    return {
      translatedText: "",
      showDrop: false,
      ismodules: [],
    };
  },
  methods: {
    customMethod() {
      this.showDrop = false;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  mounted() {
    this.ismodules = this.$store.state.ismodules;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.SiteFooter {
  background-color: #fff;
  padding: 30px 0;
  .links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    a {
      color: $blue;
      font-size: 14px;
      font-weight: 500;
      margin: 0 20px;
      &:hover {
        color: $blue;
      }
    }
  }
  .socials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    a {
      color: $blue;
      font-size: 20px;
      margin: 0 15px;
      &:hover {
        color: $blue;
      }
    }
  }
  .copyright {
    text-align: center;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #939393;
      a {
        color: $blue;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .top-bar {
    flex-direction: column;
    align-items: flex-start;
    .left,
    .right {
      margin-bottom: 10px;
    }
    .right {
      .a {
        margin-right: 0;
      }
    }
  }
  .navbar {
    height: auto;
    .navbar-item {
      flex-wrap: wrap;
      .a {
        width: 100%;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .top-bar {
    .left,
    .right {
      p {
        font-size: 10px;
      }
    }
  }
}
</style>