<template>
    <site-navbar/>

    <div class="staff-info">
      <div class="general">
            <h1 class="text-blue-500 underline font-bold">General</h1>
            <p><span class="font-bold">Names :  </span>{{ user.names }}</p>
            <p><span class="font-bold">Gender :  </span>{{ user.gender }}</p>
            <p><span class="font-bold">Telephone :  </span> {{ user.telephone }}</p>
            <p><span class="font-bold">Email Address :  </span> {{ user.emailAddress }}</p>
            <p><span class="font-bold">Passport/ID :  </span>{{ user.id_passport_number }}</p>      
      
            <div v-if="user.userRole || user.nationalityName ">
              <p><span class="font-bold">Role :  </span>{{ user.userRole.roleName }}</p>      
              <p><span class="font-bold">Nationality :  </span>{{ user.nationalityName.countryName }}</p>      
            </div>

            <router-link :to="{name:'EditProfile' }" class="a" >
            <button type="submit" class="submit">{{ $txt("Complete your Profile") }}</button>
          </router-link>

        </div>

        <div  v-if="profile" class="contacts">
          <h1 class="text-blue-500 underline font-bold">Updated Info</h1>
          <p><span class="font-bold">Location: </span>{{ profile.currentLocation.cell }}, {{ profile.currentLocation.sector }}, {{ profile.currentLocation.district }} , {{ profile.currentLocation.province }}</p>
          <p><span class="text-center underline text-blue-300">Education Background</span>{{ profile.educationBackground.degrees.degreeName.degreeName }}</p>
          <p><span class="font-bold">School Name: </span>{{ profile.educationBackground.schoolName }}</p>
          <p><span class="font-bold">Graduation Year: </span>{{ profile.educationBackground.graduationYear }}</p>
          <p><span class="font-bold">Biography: </span>{{ profile.personBiography }}</p>
        </div>
    </div>

      
  </template>
  
  <script>
  export default {
    data() {
      return {
      user:[],
      profile:null

      };

    },

    methods:{
      getProfile() {
      this.$startLoader();
      this.$store
        .dispatch("GET_USER_PROFILE", { token: this.$getToken() })
        .then((res) => {
          this.profile = res.data.data.data;
          this.$stopLoader();
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$userLogout();
          }
        });
    },
    },

    mounted(){

      this.user = this.$getUser();
      this.getProfile();

    }
  };
  </script>
<style  lang="scss" scoped>
@import "@/assets/scss/main.scss";

.staff-info {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; 
}

.general,
.contacts {
  flex: 1;
  padding: 10px;
  margin-top: 40px; 
  background-color: #fff;
  padding: 5%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit {
  margin-top: 40px;
  width: 200px;
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;
  outline: none;
  background: $blue;
  color: white;
  font-weight: 600;
}

.general {
  margin: 0 auto; /* Center the content */
}

.contacts {
  margin: 0 auto; /* Center the content */
}

@media screen and (max-width: 768px) {
  .general, .contacts {
    margin: 10px; /* Add some space between the items */
  }
}

@media screen and (max-width: 576px) {
  .staff-info {
    flex-direction: column;
  }
}
</style>

  