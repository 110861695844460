import { API_URL } from '../variables';
import axios from 'axios'
const GET_USER_PROFILE_URL = API_URL + '/user/profile';
const EDIT_USER_PROFILE_URL = API_URL + '/user/profile';



const profile = {
    state: {
    },
    getters: {},
    mutations: {},
    actions: {
        ['GET_USER_PROFILE']: ({ commit }, payload) => {
            return axios({
                url: GET_USER_PROFILE_URL ,
                method: "GET",
                data: payload,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        },
        ['EDIT_USER_PROFILE']: ({ commit }, payload) => {
            return axios({
                url: EDIT_USER_PROFILE_URL,
                method: "POST",
                data: payload.data,
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
        }
            

    },
    modules: {},
}
export default profile;