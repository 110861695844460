<template>
  <div class="Register">
    <site-navbar />
    <div class="page-content">
      <form action="" @submit.prevent="registerUser()">
        <div class="form-header">
          <label for="" class="main-head"
            >{{ $txt("Create") }} {{ $txt("Account") }}</label
          >
          <!-- progress bar from 1 to 2 -->
          <div class="progress-bar">
            <span class="step" :class="{ active: formStep >= 0 }">1</span>
            <div class="progress">
              <div class="bar" :class="{ active: formStep == 1 }"></div>
            </div>
            <span class="step" :class="{ active: formStep == 1 }">2</span>
          </div>
        </div>
        <div class="form-body" v-show="formStep == 0">
          <div class="form-group">
            <input
              type="text"
              name="fullname"
              :placeholder="$txt('Fullname')"
              v-model="user.names"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              name="username"
              :placeholder="$txt('Username')"
              v-model="user.username"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              name="email"
              :placeholder="$txt('Email')"
              v-model="user.email"
            />
          </div>
          <div class="form-group">
            <select name="" id="" v-model="user.gender">
              <option value="">Gender</option>
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>
          <div class="form-group">
            <button class="submit" @click.prevent="goNext(1)">
              {{ $txt("Next") }}
            </button>
          </div>
        </div>
        <!--formStep Telephone, Password -->
        <div class="form-body" v-show="formStep == 1">
          <div class="form-group">
            <input
              type="text"
              name="telephone"
              :placeholder="$txt('Telephone')"
              v-model="user.telephone"
            />
          </div>
          <div class="form-group">
            <select name="" id="" v-model="user.nationality">
              <option value="">Select nationality</option>
              <option
                :value="country.countryId"
                v-for="country in countries"
                :key="country.countryId"
              >
                {{ country.countryName }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <input
              type="text"
              name="idNumber"
              :placeholder="$txt('National ID / Passport')"
              v-model="user.idNumber"
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              name="password"
              :placeholder="$txt('Password')"
              v-model="user.password"
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              name="confirm-password"
              :placeholder="$txt('Confirm Password')"
              v-model="user.cpassword"
            />
          </div>
          <div class="form-group">
            <button class="prev" @click.prevent="goNext(-1)">
              {{ $txt("Previous") }}
            </button>
            <button type="submit" class="submit">{{ $txt("Register") }}</button>
          </div>
        </div>
        <div class="form-header">
          <label for="" class="dont"
            >{{ $txt("Already have an account?") }}
            <router-link :to="{ name: 'Login' }">{{
              $txt("Login")
            }}</router-link>
          </label>
        </div>
        <div class="form-footer">
          <h4>{{ $txt("Contact info@infomaster.rw for support") }}</h4>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formStep: 0,
      user: {
        names: "",
        username: "",
        email: "",
        password: "",
        telephone: "",
        idNumber: "",
        nationality: "",
        cpassword: "",
        gender: "",
        fallback_url: "",
      },
      countries: [],
    };
  },
  methods: {
    goNext(num) {
      if (num == 1) {
        // check if the user has filled all the fields
        if (
          this.user.names == "" ||
          this.user.username == "" ||
          this.user.email == "" ||
          this.user.gender == ""
        ) {
          this.$notify({
            title: "Error",
            type: "error",
            text: "Please fill all fields",
          });
          return;
        } else {
          this.formStep += num;
        }
      } else {
        this.formStep += num;
      }
    },
    registerUser() {
      // check if all fields are filled
      if (
        this.user.names == "" ||
        this.user.username == "" ||
        this.user.email == "" ||
        this.user.gender == "" ||
        this.user.telephone == "" ||
        this.user.idNumber == "" ||
        this.user.password == "" ||
        this.user.cpassword == "" ||
        this.user.nationality == ""
      ) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Please fill all fields",
        });
        return;
      } else {
        // check if the passwords match
        if (this.user.password != this.user.cpassword) {
          this.$notify({
            title: "Error",
            type: "error",
            text: "Passwords do not match",
          });
          return;
        } else {
          this.$startLoader();
          this.$store
            .dispatch("USER_REGISTER", this.user)
            .then((response) => {
              if (response.data.status) {
                this.$notify({
                  title: "Success",
                  type: "success",
                  text: response.data.data.message,
                });
                this.$router.push({ name: "EmailVerification" });
                this.$stopLoader();
              }
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                type: "error",
                text: err.response.data.error,
              });
              this.$stopLoader();
            });
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch("GET_COUNTRIES").then((res) => {
      this.countries = res.data.data.data;
    });
    this.user.fallback_url = process.env.VUE_APP_REGISTER_CALLBACK;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.page-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    width: 100%;
    max-width: 600px;
    min-height: 500px;
    padding: 4rem 5rem;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: relative;

    .form-header {
      margin-bottom: 20px;
      .progress-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        .step {
          width: 50px;
          height: 40px;
          border-radius: 50%;
          background: #ddd;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.2rem;
          font-weight: 600;
          color: #fff;
          &.active {
            background: $blue;
          }
        }
        .progress {
          width: 100%;
          background: #ddd;
          .bar {
            width: 0%;
            height: 5px;
            background: #ddd;
            position: relative;
            background: $blue;
            &.active {
              width: 100%;
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }
      .main-head {
        display: block;
        text-align: center;
        font-size: 20px;
        margin-bottom: 10px;
        span {
          color: $blue;
          font-weight: 600;
        }
      }
      .dont {
        display: block;
        text-align: center;
        span,
        a {
          color: $blue;
          font-weight: 600;
        }
      }
    }
    .form-footer {
      position: absolute;
      bottom: 20px;
      width: calc(100% - 10rem);
      h4 {
        text-align: center;
      }
    }
  }
}
</style>